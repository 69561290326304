<template>
  <div id="conversations-detail" v-if="appointment">
    <portal to="new-header">
      <el-header class="new-header">
        <div class="title">Görüşme Detayları</div>
        <div class="tab-menu">
          <el-menu class="el-menu-demo" default-active="1" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1">
              <i class="icon-details"></i>
              <span> Detayları</span>
            </el-menu-item>
            <el-menu-item index="2">
              <i class="icon-invoice"></i>
              <span>Ücret</span>
            </el-menu-item>
            <el-menu-item index="3">
              <i class="menu-icon-chat"></i>
              <span>Mesajlar</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-header>
    </portal>
    <div class="title">
      Görüşme <span>#{{ appointment.AppointmentNo }}</span>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Görüşmelerim</el-breadcrumb-item>
        <el-breadcrumb-item>{{ breadcrumb }}</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <div class="buttons-group">
        <el-button>
          <i class="icon-print" ></i>
        </el-button>
      </div> -->
    </div>

    <transition :key="activeName">
      <div v-if="activeName == 1 && appointment.PatientDiagnosis" class="detail">
        <div class="doctor">
          <div class="head">
            <div class="title mb-20">Doktor</div>
            <div class="date" v-if="appointment.StartDate == null">Oluşturulma Zamanı: {{ $moment(appointment.CreatedDate).format("DD.MM.YYYY HH:mm") }}</div>
            <div class="date" v-else>Görüşme Zamanı: {{ $moment(appointment.StartDate).format("DD.MM.YYYY HH:mm") }} - {{ $moment(appointment.EndDate).format("DD.MM.YYYY HH:mm") }}</div>
          </div>
          <div class="content" v-if="appointment.DoctorUser != null">
            <div class="doktor-nick"><i class="menu-icon-user"></i> <span>İsim: </span>{{ appointment.DoctorUser.Title }}</div>
          </div>
          <div class="content" v-else>
            <p>Görüşmeye herhangi bir doktor katılmadı.</p>
          </div>
        </div>
        <div class="my-complaints">
          <div class="head">
            <div class="title">Şikayetlerim</div>
          </div>
          <div class="content" v-if="appointment.PatientDiagnosis != null">
            <template v-if="appointment.PatientDiagnosis.Title != null || appointment.PatientDiagnosis.Details != null">
              <p v-html="appointment.PatientDiagnosis.Title"></p>
              <p v-html="appointment.PatientDiagnosis.Details"></p>
            </template>
            <p v-else>Şikayet belirtilmemiş.</p>
          </div>
        </div>
        <div class="doctor-note" v-if="appointment.PatientDiagnosis">
          <div class="head">
            <div class="title">Doktor Notları</div>
          </div>
          <div class="content doc-note" v-if="appointment.PatientDiagnosis.DoctorNotes != null">
            <p>{{ appointment.PatientDiagnosis.DoctorNotes }}</p>
          </div>
          <div class="content" v-else>
            <p>Herhangi bir not kaydedilmedi.</p>
          </div>
        </div>
        <!-- <div class="diagnosis">
          <div class="head">
            <div class="title">Tanı Kodu</div>
          </div>
          <div class="content" v-if="appointment.PatientDiagnosis.Diagnosises">
            <p v-for="(item, index) in appointment.PatientDiagnosis.Diagnosises" :key="index">{{ item.Title }} - {{ item.Description }}</p>
          </div>
          <div class="content" v-else>
            <p>Doktor tarafından bir tanı kodu belirtilmedi.</p>
          </div>
        </div>
        <div class="diagnosis">
          <div class="head">
            <div class="title">Tanı</div>
          </div>
          <div class="content" v-if="appointment.PatientDiagnosis.DoctorDiagnosis != null">
            <p>{{ appointment.PatientDiagnosis.DoctorDiagnosis }}</p>
          </div>
          <div class="content" v-else>
            <p>Doktor tarafından bir tanı belirtilmedi.</p>
          </div>
        </div> -->
        <!-- <div class="diagnosis">
          <div class="head">
            <div class="title">Reçeteler</div>
          </div>
          <div class="content" v-if="presciriptions.length > 0">
            <p>{{ appointment.PatientDiagnosis.DoctorDiagnosis }}</p>
          </div>
          <div class="content" v-else>
            <p>Doktor tarafından bir reçete yazılmadı.</p>
          </div>
        </div> -->
        <!-- <div class="diagnosis">
          <div class="head">
            <div class="title">Sevk Durumu</div>
          </div>
          <div class="content" v-if="appointment.PatientDiagnosis.TransferHospital != false">
            <p>{{ appointment.DoctorUser.Title }} tarafından hastaneye sevk edildi.</p>
          </div>
          <div class="content" v-else>
            <p>Doktor tarafından hastaneye sevk edilmedi.</p>
          </div>
        </div> -->
        <div class="diagnosis">
          <div class="head">
            <div class="title">Hasta Değerlendirme Notu</div>
          </div>
          <div class="content" v-if="appointment.RateInfo.Notes != null">
            <p>{{ appointment.RateInfo.Notes }}</p>
          </div>
          <div class="content" v-else>
            <p>Hasta tarafından değerlendirme notu girilmedi.</p>
          </div>
        </div>
        <div class="diagnosis">
          <div class="head">
            <div class="title">Bizi Nereden Buldunuz?</div>
          </div>
          <div class="content" v-if="appointment.RateInfo.HowYouReach != null">
            <p>{{ appointment.RateInfo.HowYouReach }}</p>
          </div>
          <div class="content" v-else>
            <p>Hasta tarafından bilgi girilmedi.</p>
          </div>
        </div>
      </div>
      <div v-if="activeName == 2" class="price">
        <div class="head">
          <div class="red-bar">
            FATURA<br />
            <span>#{{ appointment.AppointmentNo }}</span>
          </div>
          <!--<div class="address-info">
            <div class="title">ELRA SAĞLIK LTD: ŞTİ</div>
            <span>Küçük Çamlıca Mah. Çilehane Yolu Cad. No:23</span>
            <span>+90 216 123 45 78</span>
            <span>info@elrahealth.com</span>
            <span>www.elrahealth.com</span>
          </div>-->
          <div class="date-logo">
            <span>{{ $moment(appointment.ExpireDate).format("DD.MM.YYYY HH:ss") }}</span>
            <!--<img src="../../assets/img/logo.svg" alt="" />-->
          </div>
        </div>
        <div class="stick top">
          <div class="title">Hizmet</div>
          <div>Ücret</div>
          <div>Miktar</div>
          <div>Tutar</div>
        </div>
        <div class="stick">
          <div class="title">Online Sağlık Danışmanlığı</div>
          <div>{{ appointment.Price }} TL</div>
          <div>1</div>
          <div>{{ appointment.Price }} TL</div>
        </div>
        <div class="price-calc">
          <div class="subtotal">ARA TOPLAM: <span>{{ ((appointmentPrice * 100) / (100 + appointmentTaxRate)).toFixed(2) }} TL</span></div>
          <div class="kdv">KDV: <span>{{ (appointmentPrice - ((appointmentPrice * 100) / (100 + appointmentTaxRate))).toFixed(2) }} TL</span></div>
          <div class="total">TOTAL: <span>{{ appointment.Price }} TL</span></div>
        </div>
      </div>
      <div v-if="activeName == 3" class="meeting" id="meeting-container">
        <div class="meeting-info">
          <div class="date" v-if="appointment.EndDate != null">{{ $moment(appointment.EndDate).format("DD.MM.YYYY") }} Tarihli Görüşme</div>
          <div class="date" v-else>{{ $moment(appointment.CreatedDate).format("DD.MM.YYYY") }} Tarihli Görüşme</div>
          <!-- <div class="buttons">
            <i class="icon-star"></i>
            <i class="icon-info"></i>
            <el-dropdown>
              <span class="el-dropdown-link">
                <i class="icon-dots"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>Action 1</el-dropdown-item>
                <el-dropdown-item>Action 2</el-dropdown-item>
                <el-dropdown-item>Action 3</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
        </div>
        <div class="meet-side" v-if="messages.length > 0">
          <div class="chat-box me" v-for="item in messages" :key="item.ID" :class="[{ me: user.ID == item.SenderUserId }, { doctor: user.ID != item.SenderUserId }]">
            <div class="top-bar">
              <div class="name">{{ item.SenderUser }}</div>
              <div class="date">{{ $moment(item.CreatedDateTime).format("HH:mm") }}</div>
            </div>
            <div class="dialog">
              <div class="attachment" v-if="item.Attachments.length > 0">
                <div class="title"><i class="icon-attachment"></i>Dosya</div>
                <div class="box" style="cursor: pointer" v-for="file in item.Attachments" :key="file.ID" @click="downloadFiles(file.ObjectKey)">
                  <div class="document">
                    <i class="icon-document"></i>
                  </div>
                  <div class="info">
                    <div class="name">{{ file.FilenameReal }}</div>
                    <div class="kb">{{ fileSize(file.FileSizeInMb) }}</div>
                  </div>
                </div>
              </div>
              {{ item.MessageText }}
            </div>
          </div>
        </div>
        <div class="no-data" v-else>
          <div class="text">Bu görüşmede canlı chat hiç kullanılmadı.</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 1,
      messages: [],
      appointment: {
        PatientDiagnosis: null,
        DoctorUser: null,
        AppointmentNo: null,
        RateInfo: null,
      },
      doctorUser: null,
      presciriptions: null,
      breadcrumb: "Detay",
      breadItems: ["Detay", "Ücret", "Mesajlar"],
    };
  },
  async beforeMount() {
    var payload = {
      AppointmentId: this.$route.params.id,
      Page: 1,
      PageSize: 100,
    };
    var res = await this.$client.post("/Appointment/ListMessages", payload);
    if (res.data.HasError) {
      this.$message.error(res.data.Message);
    } else {
      this.messages = res.data.Data.Items.reverse();
    }

    this.$nextTick(() => {
      this.jQuery(".meet-side").animate({ scrollTop: this.jQuery(".meet-side").prop("scrollHeight") }, 250);
    });
  },
  async mounted() {
    var res = await this.$client.post("/Appointment/Get", { AppointmentId: this.$route.params.id });
    if (res.data.HasError) {
      this.$message.error(res.data.Message);
    } else {
      this.appointment = res.data.Data.Appointment;
      this.doctorUser = res.data.Data.Doctor;
      this.presciriptions = res.data.Data.Presciriptions;
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    handleSelect(key) {
      this.activeName = key;
    },
  },
  watch: {
    activeName() {
      this.breadcrumb = this.breadItems[this.activeName - 1];
      if (this.activeName == 3) {
        this.$nextTick(() => {
          this.jQuery(".meet-side").animate({ scrollTop: this.jQuery(".meet-side").prop("scrollHeight") }, 250);
        });
      }
    },
  },
};
</script>
